import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StripeService } from '../../services/stripe.service';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { AppConfigService, DomainConfig } from 'src/app/app-config.service';
import { PaymentTriggerService } from 'src/app/shared/payment/payment-trigger-service.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  stripe!: Stripe;
  elements!: StripeElements;
  paymentElement: any;
  cardElement: any;
  paymentInProgress = false;
  currentConfigs: DomainConfig;
  loading = false;
  paymentError: string | null = null;
  paymentSuccess = false;
  paymentContext: any;

  constructor(
    private appConfigService: AppConfigService,
    private stripeService: StripeService,
    private paymentTriggerService: PaymentTriggerService,
    private router: Router
  ) { this.currentConfigs = this.appConfigService.currentConfig }

  @ViewChild('paymentElement') paymentElementRef!: ElementRef;

  async ngOnInit(): Promise<void> {
    this.stripe = await loadStripe(this.currentConfigs.stripePublishableKey);

  
    // const elements = this.stripe.elements();
    // this.cardElement = elements.create('card');
    // this.cardElement.mount('#card-element');

    // this.paymentContext = this.stripeService.getStripePaymentContext();
    this.elements = this.stripe.elements({ mode: 'payment' });

    this.paymentElement = this.elements.create('payment');
    this.paymentElement.mount(this.paymentElementRef.nativeElement);
  }

  async handlePayment(context): Promise<void> {
    if (!this.paymentContext) return;
    this.paymentInProgress = true;
    try {
      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: this.paymentContext.returnUrl,
        },
      });
      // const { paymentMethod, error: paymentMethodError } = await this.stripe.createPaymentMethod({
      //   type: 'card',
      //   card: this.cardElement
      // });

      // if (paymentMethodError) {
      //   console.error('Payment method creation error:', paymentMethodError);
      //   this.paymentInProgress = false;
      //   return;
      // }

      // const { clientSecret, paymentIntentId } = await this.stripeService
      //   .createPaymentIntent(this.paymentContext.amount)
      //   .toPromise();
      // console.log(clientSecret, paymentIntentId);

      // const { paymentIntent, error } = await this.stripe.confirmCardPayment(clientSecret, {
      //   payment_method: {
      //     card: this.cardElement,
      //   },
      // });

      if (error) {
        console.error('Payment confirmation error:', error);
        this.paymentInProgress = false;
        return;
      }

      this.router.navigateByUrl(this.paymentContext.returnUrl);
      this.stripeService.clearStripePaymentContext();
    } catch (err) {
      console.error(err);
      alert('An error occurred during payment.');
      this.router.navigateByUrl(this.paymentContext.returnUrl);
      this.stripeService.clearStripePaymentContext();
    } finally {
      this.paymentInProgress = false;
    }
  }

}

